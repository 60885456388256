<template>
  <div class="form py-12 flex flex-col items-center min-h-screen">
    <!-- Step 1 -->
    <template v-if="active == 1">
      <div class="form__intro w-10/12">
        <div class="w-full flex flex-col items-center md:flex-row">
          <div class="w-full md:w-3/12">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="w-full md:w-6/12 my-4 md:m-0">
            <h1 class="text-primary text-4xl md:text-5xl text-center">
              ABFAHRT
            </h1>
          </div>
          <div class="w-full md:w-3/12"></div>
        </div>

        <p class="text-base text-primary text-center">
          Kinderleicht die neue Captrain-Signatur erstellen.
        </p>
        <p class="text-black text-base text-center my-9">
          Liebe Kolleginnen und Kollegen, damit Sie Ihre persönliche
          E-Mail-Signatur im Captrain Corporate Design erstellen können,
          benötigen wir im ersten von drei Schritten die Informationen, die Ihre
          neue Signatur enthalten soll. Tragen Sie diese bitte in die
          untenstehenden Formularfelder ein – vielen Dank!
        </p>
      </div>
      <div class="form__input grid md:grid-cols-2 gap-6 w-8/12 sm:grid-cols-1">
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Titel (Optional)"
            v-model="form.salutation"
            type="text"
          />
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Vorname*"
            v-model="form.firstName"
            type="text"
            :class="{ 'border-red': error.includes('firstName') }"
          />
          <p v-if="error.includes('firstName')" class="mt-2 text-red text-sm">
            Wrong Entry
          </p>
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Nachname*"
            v-model="form.name"
            type="text"
            :class="{ 'border-red': error.includes('name') }"
          />
          <p v-if="error.includes('name')" class="mt-2 text-red text-sm">
            Wrong Entry
          </p>
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Position*"
            v-model="form.position"
            type="text"
            :class="{ 'border-red': error.includes('position') }"
          />
          <p v-if="error.includes('position')" class="mt-2 text-red text-sm">
            Wrong Entry
          </p>
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Email*"
            v-model="form.email"
            type="text"
            :class="{ 'border-red': error.includes('email') }"
          />
          <p v-if="error.includes('email')" class="mt-2 text-red text-sm">
            Wrong Entry
          </p>
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Telefon* +49 30 123456-78"
            v-model="form.phone"
            type="text"
            :class="{ 'border-red': error.includes('phone') }"
          />
          <p v-if="error.includes('phone')" class="mt-2 text-red text-sm">
            Wrong Entry
          </p>
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Mobil (Optional) +49 1525 1234567"
            v-model="form.mobilePhone"
            type="text"
          />
        </div>
        <div class="wrap">
          <input
            class="border-gray p-4"
            placeholder="Fax (Optional)"
            v-model="form.fax"
            type="text"
          />
        </div>
      </div>

      <div class="text-black text-base my-9">
        <p class="text-black">
        Achten Sie hierbei bitte auf die richtige Schreibweise Ihrer
        Telefonnummer: <br />
        <ul>
          <li class="text-black">
            •	 +49, keine 0 in Klammern
          </li>
          <li class="text-black">
            •	Leerzeichen nur zwischen +49 und der Vorwahl und zwischen Vorwahl und Nummer
          </li>
          <li class="text-black">
            •	bei Bedarf kann die Vorwahl mit einem Bindestrich von der Nummer getrennt werden
          </li>
        </ul>
        </p>
        <br>
        <p class="text-black">
                  Beispiele <br />
Telefon: +49 30 123456-78 <br />
Mobil: +49 1525 1234567 <br />
        </p>
      </div>
    </template>
    <!-- Step 2 -->
    <template v-if="active == 2">
      <div class="form__intro w-10/12">
        <div class="w-full flex flex-col items-center md:flex-row">
          <div class="w-full md:w-3/12">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="w-full md:w-6/12 my-4 md:m-0">
            <h1 class="text-primary text-4xl md:text-5xl text-center">
              ZWISCHENSTOPP
            </h1>
          </div>
          <div class="w-full md:w-3/12"></div>
        </div>
        <p class="text-base text-primary text-center">
          Bitte wählen Sie das entsprechende Unternehmen der Captrain-Gruppe
          aus.
        </p>
        <p class="text-black text-base text-center my-9">
Den Großteil des Aufwandes bei der Erstellung Ihrer neuen Captrain E-Mail-Signatur haben Sie bereits erledigt, denn Ihre individuellen Daten sind nun im System.
        </p>
      </div>
      <div class="w-8/12">
        <v-select :options="companies" v-model="selectedCompany"></v-select>
      </div>
    </template>
    <!-- Step 3 -->
    <template v-if="active == 3">
      <div class="form__intro w-10/12">
        <div class="w-full flex flex-col items-center md:flex-row">
          <div class="w-full md:w-3/12">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="w-full md:w-6/12 my-4 md:m-0">
            <h1 class="text-primary text-4xl md:text-5xl text-center">
              ZIELBAHNHOF
            </h1>
          </div>
          <div class="w-full md:w-3/12"></div>
        </div>
        <p class="text-base text-primary text-center">
          Neue E-Mail-Signatur in Ihrem Endgerät verwenden.
        </p>
        <p class="text-black text-base text-center my-9">
Fast geschafft, denn Ihre neue E-Mail-Signatur ist nun fertig und Sie können diese bereits weiter unten betrachten. <br />
Sollte sich ein Fehler bei der Dateneingabe im vorherigen Schritt eingeschlichen haben, benutzen Sie bitte den untenstehenden „Zurück“-Button. Sofern alles stimmt, klicken Sie bitte auf einen der ebenfalls unten aufgeführten Button mit der Aufschrift „Signatur-Quellcode kopieren“.  <br />
Nun muss der kopierte Quellcode nur noch in das von Ihnen verwendete Endgerät gebracht werden. Wie das geht, erfahren Sie im vierten und letzten Schritt. Nutzen Sie dazu bitte entweder die Anleitung Office 365 für Outlook Web, Smartphones sowie Tablets oder die Anleitung Windows für die Outlook App auf dem Desktop. Herzlichen Dank! <br />
        </p>
      </div>
      <div class="w-full flex items-center">
        <div class="h-0.5 bg-primary flex-grow"></div>
        <button
          @click.stop="toggleShowCode"
          :class="{
            'bg-primary px-8 py-3 text-white': !showCode,
            'bg-white border-2 border-primary px-8 py-3 text-gray': showCode,
          }"
        >
          ANSICHT
        </button>
        <div class="h-0.5 bg-primary w-16"></div>
        <button
          @click.stop="toggleShowCode"
          :class="{
            'bg-primary px-8 py-3 text-white': showCode,
            'bg-white border-2 border-primary px-8 py-3 text-gray': !showCode,
          }"
        >
          QUELLCODE
        </button>
        <div class="h-0.5 bg-primary flex-grow"></div>
      </div>
      <div class="w-full md:w-8/12">
        <iframe
          width="100%"
          height="500px"
          :srcdoc="createStr()"
          frameborder="0"
          v-if="!showCode"
        ></iframe>
        <div class="my-4" v-else>{{ createStr() }}</div>
        <div class="my-4 hidden" id="signatureHtml" v-html="createStr()"></div>
      </div>
      <div class="w-full flex items-center">
        <div class="h-0.5 bg-primary flex-grow"></div>
      </div>
      <div class="w-8/12 flex justify-between mt-4 mb-2 flex-col md:flex-row">
        <button
          @click="copyToClip()"
          class="bg-white border-2 border-primary px-8 py-3 mt-4 md:m-0 md:mx-1 text-gray text-center w-full md:w-6/12"
        >
          <div class="text-gray">Signatur-Quellcode kopieren</div>
          Office 365
        </button>
        <button
          @click="copyCode()"
          class="bg-white border-2 border-primary px-8 py-3 text-gray mt-4 md:m-0 md:mx-1 text-center w-full md:w-6/12"
        >
          <div class="text-gray">Signatur-Quellcode kopieren</div>
          Windows
        </button>
      </div>
      <div class="w-8/12 flex justify-between mt-4 mb-4 flex-col md:flex-row">
        <router-link
          target="_blank"
          :to="{
            name: 'tutorial',
            query: { kind: 'office365', signature: createStr() },
          }"
          class="bg-white border-2 border-primary px-8 py-3 mt-4 md:m-0 md:mx-1 text-gray text-center w-full md:w-6/12"
        >
          <div class="text-gray">Anleitung Office 365</div>
          <small class="text-gray">Outlook Web, Smartphones, Tablets</small>
        </router-link>
        <router-link
          target="_blank"
          :to="{
            name: 'tutorial',
            query: { kind: 'windows', signature: createStr() },
          }"
          class="bg-white border-2 border-primary px-8 py-3 text-gray mt-4 md:m-0 md:mx-1 text-center w-full md:w-6/12"
        >
          <div class="text-gray">Anleitung Windows </div>
          <small class="text-gray">Outlook App (Desktop)</small>
        </router-link>
      </div>
    </template>
    <div class="flex-grow"></div>
    <captrain-stepper
      @next-click="nextClick"
      @back-click="backClick"
      :steps="this.steps"
      :active="this.active"
      class="mt-4"
    />
  </div>
</template>

<script>
import CaptrainStepper from "@/components/Stepper.vue"

export default {
  name: "vue-form",
  components: {
    CaptrainStepper,
  },
  props: {},
  data() {
    return {
      // Stepper
      active: 1,
      steps: 3,

      // Step 1
      salutation: ["Herr", "Frau"],
      form: {
        salutation: "",
        firstName: "",
        name: "",
        position: "",
        email: "",
        phone: "",
        mobilePhone: "",
        fax: "",
      },
      notValidated: ["salutation", "mobilePhone", "fax"],
      error: [],

      // Step 2
      companies: [],
      selectedCompany: "",

      // Step 3,
      showCode: false,

      debug: "",
    }
  },
  computed: {
    dropdownLabel(item) {
      return `${item} 1`
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.loadData()
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  beforeRouteLeave(to, from, next) {
    let r = confirm(
      "Are you sure ? Your data will be lost if you leave this page"
    )

    if (r) {
      next()
    } else {
      this.to = to
    }
  },
  destroyed() {},
  methods: {
    nextClick() {
      // Validation Step 1
      if (!this.validateStep1()) return
      if (this.active < this.steps) this.active += 1
    },
    backClick() {
      if (this.active > 1) this.active -= 1
    },
    async loadData() {
      try {
        const response = await this.axios.get("api/company")
        this.companies = response.data.data.map((x) => {
          return {
            ...x,
            label: x.company,
          }
        })
      } catch (error) {
        this.debug = error
      }
    },
    validateStep1() {
      this.error = []
      for (let key in this.form) {
        if (key == "salutation" || key == "mobilePhone" || key == "fax") {
          null
        } else if (this.form[key] == null || this.form[key] == "") {
          this.error.push(key)
        }
      }
      if (this.error.length > 0) return false
      return true
    },
    createStr() {
      return `
      <!DOCTYPE html
          PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html lang="de">

      <head>
          <meta name="viewport" content="width=device-width">
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style type="text/css">
            @media only screen and (max-width:480px) {
            }

            @media only screen and (min-width:1025px) {
            }

            @media only screen and (max-width:768px) {
              .logo-none {
                display: none;
              }
            }
          </style>
      </head>

      <body id="foo" style="-webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; font-family: Arial, sans-serif; padding: 30px;">
          <p style="margin: 10px 0; font-size: 10pt;">Mit freundlichen Gr&#xFC;&#xDF;en</p>
          <p style="margin:0; padding:0;font-size: 10pt;">${
            this.form.salutation !== "" ? this.form.salutation : ""
          } ${this.form.firstName !== "" ? this.form.firstName : ""} ${
        this.form.name !== "" ? this.form.name : ""
      }</p>
          <p style="margin-top:0; font-size: 10pt; margin-bottom: 10px;">${
            this.form.position !== "" ? this.form.position : ""
          }</p>
          <p style="margin:0; font-size: 10pt;font-weight: 400;">--</p>
          <h2 style="margin-top:0px;margin-bottom:0;font-size: 10pt;">${
            this.selectedCompany.companyName != ""
              ? this.selectedCompany.companyName
              : ""
          }</h2>
          <p style="font-size: 8pt; margin: 0">
            ${
              this.selectedCompany.subtitle === 1 ?
              'Ein Unternehmen der Captrain Deutschland-Gruppe'
              : ''
            }
          </p>
          <img src="https://geb-staging.de/captrain/logo_captrain_space.png" width="125" alt="Captrain Logo" class="logo-none" height="59" style="display: block;"/>
          <p style="margin: 1px 0;font-size: 10pt;">${this.selectedCompany.address}</p>
          <p style="margin: 1px 0; font-size: 10pt;text-decoration: none;color: black;">Tel ${
            this.form.phone != "" ? this.form.phone : "-"
          }</p>
          ${
            this.form.fax != ""
              ? `<p style="margin: 1px 0; font-size: 10pt;text-decoration: none;color: black;">
                Fax ${this.form.fax}
              </p>`
              : ""
          }
          ${
            this.form.mobilePhone != ""
              ? `<p style="margin: 1px 0; font-size: 10pt;text-decoration: none;color: black;">
                Mobil ${this.form.mobilePhone}
              </p>`
              : ""
          }
          <a href="mailto:${this.form.email}">
            <font color="#bdcd00" style="margin: 1px 0; font-size: 10pt;text-decoration: none;color: #bdcd00 !important;">${
              this.form.email
            }</font>
          </a>
          <br>
          <br>
          <table>
            <tr>
              <td valign="center" style="vertical-align: middle !important;">
                <h5 style="margin: 0;margin-right: 16px;line-height: 12px;vertical-align: middle;">
                  <a href="https://www.captrain.de/" style="text-decoration: none; color: black;font-family: Arial, sans-serif;margin-left: -2px;">captrain.de</a>
                </h5>
              </td>
              <td style="vertical-align: middle !important;">
                <a style="vertical-align: middle;" href="https://www.xing.com/pages/captraindeutschland"><img style="width: 18px;height: 18px;" src="https://identitaetsverstaerker.com/signaturen/captrain/icon03.png" alt="Xing Captrain" height="18" width="18"></a>
              </td>
              <td style="vertical-align: middle !important;">
                <a style="vertical-align: middle;" href="https://www.kununu.com/de/captrain-deutschland"><img style="width: 18px;height: 18px;" src="https://identitaetsverstaerker.com/signaturen/captrain/icon01.png" alt="Kununu Captrain" height="18" width="18" style="width:18px, height:18px;"></a>
              </td>
              <td style="vertical-align: middle !important;">
                <a style="vertical-align: middle;" href="https://www.linkedin.com/company/captraindeutschland/?originalSubdomain=de"><img style="width: 18px;height: 18px;" src="https://identitaetsverstaerker.com/signaturen/captrain/icon02.png" alt="Linkedin Captrain" height="18" width="18" style="width:18px, height:18px;"></a>
              </td>
              <td style="vertical-align: middle !important;">
                <a style="vertical-align: middle;" href="https://www.facebook.com/CaptrainDeutschland/"><img style="width: 18px;height: 18px;" src="https://identitaetsverstaerker.com/signaturen/captrain/icon05.png" alt="Facebook Captrain" height="18" width="18" style="width:18px, height:18px;"></a>
              </td>
              <td style="vertical-align: middle !important;">
                <a style="vertical-align: middle;" href="https://www.instagram.com/captraindeutschland/"><img style="width: 18px;height: 18px;" src="https://identitaetsverstaerker.com/signaturen/captrain/icon04.png" alt="Instagram Captrain" height="18" width="18" style="width:18px, height:18px;"></a>
              </td>
            </tr>
          </table>
          <br>
          <p style="font-size: 8pt;margin-top: 1px;">
              ${this.selectedCompany.companyPT}<br>
              ${this.selectedCompany.founders}
          </p>
          <p style="margin:0;font-weight: 400; font-size: 8pt;">----------</p>
          <p style="font-size: 8pt; margin: 0px 0px 4px 0;">
              Diese E-Mail enth&#228;lt vertrauliche und/oder rechtlich geschützte Informationen.<br>
              This e-mail contains confidential and/or privileged information.
          </p>
          <p style="font-size: 8pt;text-decoration: none !important;margin-bottom: 24px;">
              Bitte beachten Sie unsere Datenschutzhinweise unter <a href="${
                this.selectedCompany.linkPrivacy
              }" style="color: #bdcd00;text-decoration: none !important;">${
        this.selectedCompany.linkPrivacy
      }</a>
      <br>
              Please note our data protection policy at <a href="https://www.captrain.de/en/data-privacy" style="color: #bdcd00;text-decoration: none !important;">https://www.captrain.de/en/data-privacy</a>
          </p>
          <br>
      </body>

      </html>
      `
    },
    toggleShowCode() {
      this.showCode = !this.showCode
    },
    copyToClip() {
      let str = document.getElementById("signatureHtml").innerHTML
      function listener(e) {
        e.clipboardData.setData("text/html", str)
        e.clipboardData.setData("text/plain", str)
        e.preventDefault()
      }
      document.addEventListener("copy", listener)
      document.execCommand("copy")
      document.removeEventListener("copy", listener)

      alert("Success Copy to Clipboard")
    },
    copyCode() {
      this.$copyText(this.createStr()).then(
        function (e) {
          alert("Success Copy to Clipboard")
          console.log(e)
        },
        function (e) {
          alert("Can not copy")
        }
      )
    },
  },
}
</script>

<style lang="scss">
.vs__dropdown-toggle {
  padding: 15px 12px;
}
</style>

<style lang="scss" scoped>
@import "./index.scss";
</style>
