<template>
  <div class="stepper flex flex-col md:flex-row">
    <button
      @click.stop="backClick"
      class="
        text-base
        cursor-pointer
        py-2
        px-8
        text-gray
        border-2 border-primary
        md:mr-2
      "
      v-if="active != 1"
    >
      ZURÜCK
    </button>
    <div class="flex my-4 md:m-0">
      <div
        class="
          w-12
          h-12
          flex flex-row
          items-center
          justify-center
          text-gray
          border-2 border-primary
          font-bold
          mx-2
          text-base
        "
        :class="{ 'bg-primary text-white': active == step }"
        v-for="step in steps"
        :key="step"
        @click.stop="stepClick(step)"
      >
        {{ step }}
      </div>
    </div>
    <button
      @click.stop="nextClick"
      class="
        text-base
        cursor-pointer
        py-2
        px-8
        text-gray
        border-2 border-primary
        md:ml-2
      "
      v-if="active != steps"
    >
      WEITER
    </button>
  </div>
</template>

<script>
export default {
  name: "captrain-stepper",
  props: {
    steps: Number,
    active: Number,
  },
  methods: {
    stepClick(step) {
      this.$emit("step-click", step);
    },
    nextClick() {
      this.$emit("next-click");
    },
    backClick() {
      this.$emit("back-click");
    },
  },
};
</script>

<style>
</style>